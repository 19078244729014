@keyframes swirl {
  0% {
    background-position: 0% 0%;
  }
  25% {
    background-position: 50% 50%;
  }
  50% {
    background-position: 100% 100%;
  }
  75% {
    background-position: 50% 50%;
  }
  100% {
    background-position: 0% 0%;
  }
}

.meshGradientBackground {
  background-color: #ffd599;
  background-image:
    radial-gradient(at 24% 50%, hsla(310, 93%, 77%, 1) 0px, transparent 50%),
    radial-gradient(at 13% 67%, hsla(45, 82%, 67%, 1) 0px, transparent 50%),
    radial-gradient(at 37% 21%, hsla(333, 89%, 67%, 1) 0px, transparent 50%),
    radial-gradient(at 78% 48%, hsla(330, 70%, 74%, 1) 0px, transparent 50%),
    radial-gradient(at 16% 35%, hsla(233, 90%, 74%, 1) 0px, transparent 50%);
  background-size: 300% 300%;
  animation: swirl 20s linear infinite;
  padding: 1rem;
  border-radius: 0.5rem;

  position: relative;
  overflow: hidden;
}

.text-dark {
  color: black;
}

.text-light {
  color: white;
}
