.switchRoot {
  all: unset;
  width: 42px;
  height: 25px;
  background-color: #c4c4c4;
  border-radius: 9999px;
  position: relative;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  cursor: pointer;
  transition: background-color 0.2s ease-in-out;
}

.switchRoot[data-state="checked"] {
  background-color: #4ade80;
}

.switchThumb {
  display: block;
  width: 21px;
  height: 21px;
  background-color: white;
  border-radius: 9999px;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s ease-in-out;
  transform: translateX(2px);
  will-change: transform;
}

.switchRoot[data-state="checked"] .switchThumb {
  transform: translateX(19px);
}
