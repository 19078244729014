.title {
  font-size: 30px;
  line-height: 1.2;
  text-transform: uppercase;
  background: linear-gradient(40deg, #ea8390, #e6564b, #c9232e, #61292b);
  background-size: 600%;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: gradient 12s ease infinite;
}

@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.no-select {
  -webkit-touch-callout: none;
  user-select: none; /* Non-prefixed version, currently */
}
