.overlay {
  position: fixed;
  inset: 0;
  background: rgba(0, 0, 0, 0.75);
}

.content {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: white;
  padding: 2rem;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.title {
  margin-bottom: 1rem;
  font-size: 1.25rem;
  font-weight: bold;
}

.description {
  margin-bottom: 1rem;
}
