.container {
  display: flex;
  flex-direction: row;
  min-height: 100vh;
  background-color: #F9F7F6;
  padding: 20px;
  gap: 20px;
}

.sidebar {
  width: 15%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.contentContainer {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.header {
  background-color: white;
  padding: 20px;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); 
  border-bottom: 2px solid #EDEAE8;
}

.main {
  flex: 1;
  background-color: white;
  padding: 20px;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Optional shadow for depth */
}
